<template>
  <div>
    <h1>Error verify page here</h1>
    <v-text-field v-model="email" />
    <v-btn @click="resend">{{ $t("buttons.send") }}</v-btn>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ErrorVerifyPage",
  data() {
    return {
      email: "",
    };
  },
  methods: {
    ...mapActions(["resendVerificationEmail"]),
    resend() {
      this.resendVerificationEmail({
        email: this.email,
      });
    },
  },
};
</script>
