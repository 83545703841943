<template>
  <validation-provider  v-slot="{ errors }" :name="label" :rules="rules">
    <v-select
      outlined
      dense
      :label="label"
      :error-messages="errors"
      :items="items"
      v-model="innerValue"
      item-text="label"
      item-value="value"
    >
    </v-select>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
export default {
  name: "FormSelectMenu",
  props: {
    label: String,
    items: Array,
    rules: [String],
    value: {
      required: false,
      type: null,
    },
  },
  components: {
    ValidationProvider,
  },
  data() {
    return {
      innerValue: null,
    };
  },
  methods: {},
  watch: {
    innerValue(newInnerValue) {
      this.$emit("input", newInnerValue);
    },
  },
};
</script>
