<template>
  <v-card outlined>
    <v-container fluid class="d-flex align-center custom-time-picker">
      <v-select
        class="time-input"
        outlined
        dense
        hide-details
        append-icon=""
        :items="items"
        @change="handleHoursChange"
        :value="hours"
        item-text="label"
      >
      </v-select>
      :
      <v-select
        class="time-input"
        dense
        outlined
        hide-details
        @change="handleMinutesChange"
        append-icon=""
        :value="minutes"
        :items="items2"
        item-text="label"
      >
      </v-select>
    </v-container>
  </v-card>
</template>

<script>
import hours from "@/store/utils/items/hours";
import minutes from "@/store/utils/items/minutes";
export default {
  name: "CustomTimePicker",
  props: {
    minutes: null,
    hours: null,
    minHour: null,
  },
  methods: {
    getItemLabel(item) {
      if (item <= 9) return "0" + item;
    },
    handleHoursChange(e) {
      this.$emit("hoursChange", e);
    },
    handleMinutesChange(e) {
      this.$emit("minutesChange", e);
    },
  },
  computed: {
    items() {
      return hours.filter((x) =>
        this.minHour ? x.value > this.minHour : true
      );
    },
    items2() {
      return minutes;
    },
  },
};
</script>

<style lang="scss">
.custom-time-picker {
  max-width: 150px !important;
}
</style>
