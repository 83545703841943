<template>
    <v-container>
    <v-layout align-center justify-center>
      <v-card>
        <v-card-title class="headline">Email trimis!</v-card-title>
        <v-card-text>
          <p>
            Un email a fost trimis pe contul dumneavoastră. Vă rugăm urmați instrucțiunile din email pentru a reseta parola.
          </p>
        </v-card-text>
      </v-card>
    </v-layout>
  </v-container>
</template>
  
  <script>

  export default {
    name: "ForgotPasswordEmailSentPage",
  };
  </script>
  