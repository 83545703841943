export const RentStatus = {
  rented: 1,
  confirmed: 2,
  rejected: 3,
  paymentConfirmed: 4,
  waitingForOnlinePayment: 5,
};

export const statusesAndColors = [
  { status: RentStatus.rented, color: "yellow", label: "Rezervat" },
  { status: RentStatus.confirmed, color: "green", label: "Confirmat" },
  { status: RentStatus.rejected, color: "red", label: "Anulat" },
  {
    status: RentStatus.paymentConfirmed,
    color: "lightgreen",
    label: "Plată confirmată",
  },
  {
    status: RentStatus.waitingForOnlinePayment,
    color: "blue",
    label: "Plată în așteptare",
  },
];
