<template>
  <div>
    <v-card v-for="(user, index) in users" :key="index" class="mb-4">
      <v-card-title>{{ user.firstName + " " + user.lastName }}</v-card-title>

      <v-card-actions>
        <v-btn @click="displayDeleteDialog" @cancel="onCancel" outlined rounded>Șterge</v-btn>
      </v-card-actions>
      <user-delete-confirmation :userId="user.userId" :show="showDeleteDialog"></user-delete-confirmation>
    </v-card>
  </div>
</template>

<script>
import {  mapActions, mapGetters, mapMutations } from "vuex";
import UserDeleteConfirmation from './UserDeleteConfirmation.vue';
export default {
  name: "UsersPage",
  components:{
    UserDeleteConfirmation
  },
  methods: {
    ...mapActions(["getUsers"]),
    ...mapMutations(["setDeleteConfirmationDialog"]),
    onCancel() {
        this.setDeleteConfirmationDialog(false)
    },
    displayDeleteDialog(){
      this.setDeleteConfirmationDialog(true);
    }
  },
  mounted(){
    this.getUsers({
        token: this.currentUser?.jwtToken
      });
    },
  computed: {
    ...mapGetters(["currentUser", "showDeleteDialog"]),
    users() {
      return this.$store.state.users.users;
    },
  }
};
</script>
