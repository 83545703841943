<template>
  <v-row no-gutters class="d-flex flex-column justify-center">
    <v-data-table
      :headers="headers"
      :loading="paymentsLoading"
      :items="payments"
      :page.sync="page"
      height="540"
      :items-per-page="10"
      class="elevation-1"
      hide-default-footer
      @page-count="pageCount = $event"
    >
      <template v-slot:[`item.paymentTime`]="{ item }"
        >{{ dateLabel(item.paymentTime) }}
      </template>
      <template v-slot:[`item.paymentConfirmed`]="{ item }"
        >{{ item.paymentConfirmed ? "DA" : "NU" }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          v-if="(!item.paymentConfirmed && (item.rentStatus == RentStatus.rented || item.rentStatus == RentStatus.waitingForOnlinePayment))"
          color="secondary"
          @click="handleGoToPayment(item)"
          small
          >Plătește</v-btn
        >
        <v-btn
          v-if="item.paymentConfirmed"
          color="primary"
          @click="handleCheckInvoice(item)"
          small
          >Verifică factura</v-btn
        >
      </template>
    </v-data-table>
    <pay-for-rent-component ref="payRef"></pay-for-rent-component>
    <v-pagination v-model="page" :length="pageCount"></v-pagination>
  </v-row>
</template>

<script>
import { getDateLabel } from "@/store/utils/functions";
import { mapActions, mapGetters } from "vuex";
import PayForRentComponent from "@/pages/pay-for-rent/PayForRentComponent.vue";
import { RentStatus } from "@/store/utils/rentStatus";
export default {
  name: "PaymentsDataTable",
  components:{
    PayForRentComponent
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
    };
  },
  methods: {
    ...mapActions(['goToPayment']),
    dateLabel(date) {
      return getDateLabel(date);
    },
    handleCheckInvoice(item) {
      console.log(item.paymentId);
    },
    handleGoToPayment(item){
      this.goToPayment({
        data:{
        userId:item.userId,
        email:this.currentUser?.email,
        rentId:item.rentId
      },
      token:this.currentUser?.jwtToken}).then(()=>{
        this.$refs.payRef.goToPayment();
      })
    }
  },
  computed: {
    ...mapGetters(["payments", "paymentsLoading", 'currentUser']),
    headers() {
      return [
        { text: "Plătitor", align: "start", value: "userPayingName" },
        { text: "Confirmat", value: "paymentConfirmed" },
        { text: "Data plății", value: "paymentTime" },
        { text: "Status", value: "paymentStatus" },
        { text: "Sumă (lei)", value: "amount" },
        { text: "Acțiuni", value: "actions", align: "end", sortable: false },
      ];
    },
    RentStatus(){
      return RentStatus;
    }
  },
};
</script>
