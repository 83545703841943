<template>
  <div class="d-block">
    <validation-observer v-if="userData">
      <form ref="form" @submit.prevent="handleSubmit">
        <v-row
          no-gutters
          class="font-weight-bold text-h4 d-flex justify-center mb-10"
          >Contul meu
        </v-row>
        <v-row no-gutters>
          <v-col cols="6" class="pr-5">
            <form-text-field label="Nume" v-model="userData.firstName">
            </form-text-field>
          </v-col>
          <v-col cols="6">
            <form-text-field label="Prenume" v-model="userData.lastName">
            </form-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6" class="pr-5">
            <form-text-field
              label="Email"
              :readonly="true"
              v-model="userData.email"
            >
            </form-text-field>
          </v-col>
          <v-col cols="6">
            <form-text-field
              label="Telefon"
              v-model="userData.phoneNumber"
            >
            </form-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="d-flex justify-center">
          <v-btn type="submit" color="success">Salvează </v-btn>
        </v-row>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import FormTextField from "@/components/form-inputs/FormTextField.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "MyProfilePage",
  components: {
    ValidationObserver,
    FormTextField,
  },

  mounted() {
    this.getUserById({
      userId: this.currentUser.userId,
      token: this.currentUser.jwtToken,
    });
  },
  methods: {
    ...mapActions(["getUserById", "updateUser"]),
    handleSubmit() {
      this.updateUser({
        form: {
          ...this.userData,
          userId: this.userProfileId,
        },
        token: this.currentUser.jwtToken,
      });
    },
  },
  computed: {
    ...mapGetters(["currentUser", "userData"]),
    userProfileId() {
      return this.$route.params.id;
    },
  },
};
</script>
