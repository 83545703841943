<template>
  <div></div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "RefreshTokenComponent",
  methods: {
    ...mapActions(["refreshToken"]),

    ...mapMutations(["setCurrentUser"]),
    handleRefreshToken() {
      const localStorageToken = localStorage.getItem("user");
      if (localStorageToken) {
        const localStorageTokenParsed = JSON.parse(localStorageToken);
        this.setCurrentUser({
          ...this.currentUser,
          jwtToken: localStorageTokenParsed,
        });
        this.refreshToken(localStorageTokenParsed);
      }
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },

  mounted() {
    const interval = setInterval(this.handleRefreshToken, 1000 * 60 * 14, true);
    this.handleRefreshToken(false);
    return () => {
      clearInterval(interval);
    };
  },
};
</script>
