<template>
  <v-container>
    <h1>Detalii</h1>
  <product-details :product="productDetails"></product-details>
  <v-divider class="mt-6"></v-divider>
  <v-row class="mt-6 bg-primary d-flex justify-center">
    <v-col xs="12" md="4">
    <v-col cols="12" class="ma-2 pa-2  font-weight-black subtitle-1 elevation-5">
      {{ productDetails.pricePerHour }} lei pe oră
    </v-col>
    <v-col cols="12" class="flex-1-0 ma-2 pa-2 font-weight-black subtitle-1 elevation-5" style="color:green">
      {{ productDetails.pricePerDay }} lei pe zi
    </v-col>
  </v-col>
  </v-row>
  <v-divider class="mt-6"></v-divider>
  <div class="text-gray mt-6">{{ productDetails.description }}</div>
  </v-container>
  
</template>

<script>
import ProductDetails from "@/components/rent-product/ProductDetails.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ProductDetailsPage",
  components:{
    ProductDetails
  },
  methods:{
    ...mapActions(["getProductDetails"]),
  },
  mounted(){
    this.getProductDetails(this.$route.params.id)
  },
  computed: {
    ...mapGetters(["productDetails"]),
  }
};
</script>

<style lang="scss">
.pe-zi {
  color:primary;
}
</style>
