<template>
    <v-card outlined class="pa-5" :loading="addProductLoading">
        <validation-observer ref="observer" v-slot="{ handleSubmit, reset }">
            <v-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="reset" ref="form"
      v-model="valid"
      lazy-validation>
                <v-row class="justify-center pa-5">
                    <div class="text-darken-1 mb-2 text-xs-body-1 text-lg-h4">
                        Aici poți adăuga produse pentru rezervare
                    </div>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="6">
                        <v-row>
                            <v-col cols="12" sm="12" md="8">
                                <form-text-field label="Nume" v-model="productData.name" rules="required"></form-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="4">
                                <form-text-field label="Preț pe oră (lei)" v-model="productData.pricePerHour" type="number"
                                    rules="required"></form-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <form-text-field label="Preț pe zi (lei)" v-model="productData.pricePerDay" type="number"
                                    rules="required"></form-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="8">
                                <form-text-area label="Descriere" v-model="productData.description" rules="required" rows="7">
                                </form-text-area>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-row>
                            <v-col cols="12">
                                <v-file-input dense v-model="productData.images" outlined placeholder="Click aici pentru a adăuga poze" clearable
                                    prepend-icon="mdi-camera" multiple chips small-chips
                                    accept="image/png, image/jpeg, image/bmp, image/webp"></v-file-input>
                            </v-col>
                            <v-col cols="12">
                                <v-carousel hide-delimiter-background height="300">
                                    <v-carousel-item v-for="(image, i) in productData.images" :key="i"
                                        :src="getImageBlob(image)" contain reverse-transition="fade-transition"
                                        transition="fade-transition">
                                    </v-carousel-item>
                                </v-carousel>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-btn type="submit" color="success" filled>Adaugă produs</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </validation-observer>
    </v-card>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver } from "vee-validate";
import FormTextField from '@/components/form-inputs/FormTextField.vue';
import FormTextArea from '@/components/form-inputs/FormTextArea.vue';
import { homePath } from '@/navigation/paths';
export default {
    name: "AddProduct",
    data() {
        return {
            productData: {
                name: null,
                description: null,
                pricePerDay: null,
                pricePerHour: null,
                images: null
            },
            loading: false,
        }
    },
    components: {
        ValidationObserver,
        FormTextField,
        FormTextArea
    },
    methods:{
        ...mapActions(["addProduct"]),
        onSubmit(){
            var form = new FormData();
            form.append("name",this.productData.name);
            form.append("description", this.productData.description);
            form.append("pricePerHour", this.productData.pricePerHour);
            form.append("pricePerDay", this.productData.pricePerDay);
            if(this.productData.images){
                this.productData.images.map((image)=>{
                form.append("images", image)
            })
            }
            this.addProduct({
                data:form,
                token:this.currentUser?.jwtToken
            }).then((data)=>{
                if(data.success){
                    this.$router.push(homePath);
                }
                requestAnimationFrame(() => {
          this.$refs.observer.reset();
        });
            })
        },
        getImageBlob(image) {
            return URL.createObjectURL(image);
    },
    },
    computed:{
        ...mapGetters(["currentUser", "addProductLoading"]),
    },
}
</script>
