<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit, reset }">
    <div class="text-h6 mt-4 mb-4">Insereaza email pentru resetarea parolei</div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="reset"
    >
    <v-row no-gutters class="d-flex" justify="center">
    <v-col xs="12" sm="4">
      <form-text-field rules="required" label="Email" v-model="formData.email">
    </form-text-field>
    </v-col>
  </v-row>
  <v-row no-gutters class="d-flex justify-center">
          <v-btn type="submit" color="primary" small
            >Trimiteți formularul
          </v-btn>
        </v-row>
    </v-form>
    </validation-observer>
</template>

<script>
import { ValidationObserver, extend } from "vee-validate";
import FormTextField from "@/components/form-inputs/FormTextField.vue";
import { mapActions } from "vuex";
import { forgotPasswordEmailSentPath } from "@/navigation/paths";

extend("required", {
  validate(value) {
    return {
      required: true,
      valid: ["", null, undefined].indexOf(value) === -1,
    };  
  },
  message: "Acest câmp este obligatoriu.",
});
export default {
  name: "ForgotPasswordPage",
  components: {
    ValidationObserver,
    FormTextField,
  },
  data(){
    return{
      valid:false,
      formData:{
        email:"",
      }
    }
  },
  methods:{
    ...mapActions(["sendForgotPasswordEmail"]),
    onSubmit(){
      this.sendForgotPasswordEmail(this.formData).then((response)=>{
        this.formData = {
          email:""
        };
        if (response.success) {
          this.$router.push(forgotPasswordEmailSentPath);
        }
      })
    }
  }
};
</script>
