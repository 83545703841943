<template>
  <validation-provider v-slot="{ errors }" :name="label" :rules="rules">
    <v-text-field
      v-model="innerValue"
      :error-messages="errors"
      :label="label"
      outlined
      dense
      :disabled="readonly"
      :type="type"
    ></v-text-field>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
export default {
  name: "FormTextField",
  data() {
    return {
      innerValue: null,
    };
  },
  components: {
    ValidationProvider,
  },
  props: {
    value: null,
    label: String,
    rules: [String],
    type: String,
    readonly: Boolean,
  },

  mounted() {
    this.innerValue = this.value;
  },
  watch: {
    value() {
      this.innerValue = this.value;
    },
    innerValue(newInnerValue) {
      this.$emit("input", newInnerValue);
    },
  },
};
</script>
