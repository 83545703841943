<template>
  <v-container id="rents-calendar" fluid class="d-block">
    <v-sheet tile height="54" width="100%" class="d-flex justify-space-between">
      <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-card-title v-if="$refs.calendar">
        {{ $refs.calendar.title }}
      </v-card-title>
      <v-btn icon class="ma-2" @click="$refs.calendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet width="100%">
      <v-calendar
        v-model="value"
        type="category"
        category-show-all
        interval-minutes="60"
        first-time="06:00"
        interval-count="17"
        :short-intervals="true"
        :category-days="1"
        :categories="productsNames"
        ref="calendar"
        :event-overlap-threshold="50"
        :events="rents"
      >
        <template v-slot:event="{ event }">
          <v-sheet height="inherit" :color="event.color">
            <v-row no-gutters class="d-flex align-center">
              <v-col cols="12">
                <div class="font-weight-bold "><v-icon small left>mdi-account</v-icon>{{ event.name }}</div>
                <div>
                  <v-icon small left>mdi-credit-card-outline</v-icon>
                  {{ paymentTypeLabel(event.paymentType) }}
                </div>
                <div class="d-flex">
                  <v-btn
                    x-small
                    block
                    color="primary"
                    @click="handleRentDetailsDialogOpen(event)"
                  >
                    Detalii
                  </v-btn>
                </div>
              </v-col>
              <template>
                  <product-rent-details
                    ref="productRentDetailsDialog"
                    :rentDetails="event"
                  >
                  </product-rent-details> </template>
            </v-row>
          </v-sheet>
        </template>
      </v-calendar>
    </v-sheet>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import {  paymentTypeItems } from "@/store/utils/paymentType";
import ProductRentDetails from "@/components/existent-rents/ProductRentDetails.vue";
export default {
  name: "RentsCalendar",
  components: {
    ProductRentDetails,
  },
  data() {
    return {
      value: "",
    };
  },
  updated() {},
  methods: {
    paymentTypeLabel(type) {
      return paymentTypeItems.find((x) => x.value === type).label;
    },
    handleRentDetailsDialogOpen(event) {
      this.$refs.productRentDetailsDialog.productDetails = event;
      this.$refs.productRentDetailsDialog.open = true;
    },
  },
  computed: {
    ...mapGetters(["rents", "rentsLoading", "productsNames", "currentUser"]),
  },
};
</script>

<style lang="scss">
#rents-calendar {
  .v-calendar {
    .v-event-timed-container {
      margin-right: 0px;
    }
  }
}
</style>
