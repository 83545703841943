<template>
  <form  method="post" :action="paymentData.url" target="_blank"> 
<input type="hidden" name="env_key" :value="paymentData.env_Key">
<input type="hidden" name="data" :value="paymentData.data">
<input ref="pay" class="d-none" type="submit" name="pay" value="mobilpay">
</form>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "PayForRentComponent",
  computed:{
    ...mapGetters(['paymentData'])
  },
  methods:{
    goToPayment(){
      console.log(this.paymentData);
      console.log(this.$refs.pay.click())
    }
  }
};
</script>
