import { baseUrl } from "@/config/apiConfig";
import { RentType } from "@/store/utils/rentType";
import axios from "axios";

function getRentEventColor(rentStatus) {
  switch (rentStatus) {
    case 1:
      return "yellow";
    case 2:
      return "green";
    case 3:
      return "red";
    case 4:
      return "lightgreen";
    case 5:
      return "blue";
  }
}

const rentsStore = {
  state() {
    return {
      rents: [],
      rentsLoading: false,
      productRents: [],
      addProductLoading:false,
    };
  },
  mutations: {
    getMyRentsPending(state) {
      state.rents = [];
      state.rentsLoading = true;
    },
    getMyRentsFulfilled(state, data) {
      state.rents = data;
      state.rents.forEach((rent) => {
        var rentDate = new Date(rent.rentedDate);
        const [year, month, day] = [
          rentDate.getFullYear(),
          rentDate.getMonth(),
          rentDate.getDate(),
        ];
        const monthie = month + 1;
        if (rent.rentType === RentType.WholeDay) {
          rent.start = year + "-" + monthie + "-" + day + " 08:00";
          rent.end = year + "-" + monthie + "-" + day + " 24:00";
        } else {
          rent.start = year + "-" + monthie + "-" + day + " " + rent.start;
          rent.end = year + "-" + monthie + "-" + day + " " + rent.end;
        }
        rent.color = getRentEventColor(rent.status);
        rent.category = rent.productName;
      });

      state.rentsLoading = false;
    },
    getMyRentsRejected(state) {
      state.rents = [];
      state.rentsLoading = false;
    },
    getProductRentedTimesPending(state) {
      state.productRents = [];
    },
    getProductRentedTimesFulfilled(state, data) {
      state.productRents = data;
    },
    getProductRentedTimesRejected(state) {
      state.productRents = [];
    },
    addProductLoading(state){
      state.addProductLoading = true;
    },
    addProductFulfilled(state){
      state.addProductLoading = false;
    },
    addProductRejected(state){
      state.addProductLoading = false;
    }
  },
  actions: {
    async getMyRents({ commit }, userId) {
      commit("getMyRentsPending");
      await axios
        .get(baseUrl + "Rents/GetMyRents", {
          params: {
            userId: userId,
          },
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          commit("getMyRentsFulfilled", response.data.response);
        })
        .catch(() => {
          commit("getMyRentsRejected");
        });
    },
    async getProductRentedTimes({ commit }, params) {
      commit("getProductRentedTimesPending");
      await axios
        .get(baseUrl + "Rents/GetProductRentedTimes", {
          params: {
            productId: params,
          },
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          commit("getProductRentedTimesFulfilled", response.data.response);
        })
        .catch(() => {
          commit("getProductRentedTimesRejected");
        });
    },
    async addProduct({commit},params){
      try{
      commit("addProductLoading");
      var { data } = await axios.post(baseUrl + "Products/AddProduct", params.data, {
        headers:{
          Authorization:`Bearer ${params.token}`,
        },
        withCredentials:true
      })
      if(data.success){
        commit("addProductFulfilled", data);
        commit("showNotification", data.message);
      }else{
        commit("addProductRejected");
        commit("showNotification", data.message);
      }
      return data;
    }
    catch(error){
      commit("addProductRejected");
      commit("showNotification", error.response.data.message);
      return error.response.data;
    }
    },
    async rentProduct({ commit }, params) {
      commit("showOverlay");
      commit("rentProductLoading");
      await axios
        .post(baseUrl + "Rents/RentProduct", params.data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${params.token}`,
          },
          withCredentials: true,
        })
        .then((response) => {
          commit("rentProductFulfilled", response);
          commit("hideOverlay");
          commit("showNotification", response?.data?.message);
        })
        .catch((error) => {
          commit("rentProductRejected");
          commit("hideOverlay");
          commit("showNotification", error?.response?.data?.message);
        });
    },
    async confirmRent({ commit }, params) {
      commit("showOverlay");
      var form = new FormData();
      form.append("rentId", params.rentId);
      await axios
        .post(baseUrl + "Rents/ConfirmRent", form, {
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
          withCredentials: true,
        })
        .then((response) => {
          commit("hideOverlay");
          commit("confirmRentFulfilled", response);
          commit("showNotification", response?.data?.message);
        })
        .catch((error) => {
          commit("hideOverlay");
          commit("showNotification", error?.response?.data?.message);
        });
    },
    async cancelRent({ commit }, params) {
      commit("showOverlay");
      var form = new FormData();
      form.append("rentId", params.rentId);
      await axios
        .post(baseUrl + "Rents/CancelRent", form, {
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
          withCredentials: true,
        })
        .then((response) => {
          commit("cancelRentFulfilled", response);
          commit("showNotification", response?.data?.message);
          commit("hideOverlay");
        })
        .catch((error) => {
          commit("hideOverlay");
          commit("showNotification", error?.response?.data?.message);
        });
    },
    async confirmRentPayment({ commit }, params) {
      commit("showOverlay");
      var form = new FormData();
      form.append("rentId", params.rentId);
      await axios
        .post(baseUrl + "Rents/ConfirmRentPayment", form, {
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
          withCredentials: true,
        })
        .then((response) => {
          commit("cancelRentFulfilled", response);
          commit("hideOverlay");
        })
        .catch(() => {
          commit("hideOverlay");
        });
    },
  },
  getters: {
    rents(state) {
      return state.rents;
    },
    rentsLoading(state) {
      return state.rentsLoading;
    },
    productRents(state) {
      return state.productRents;
    },
    addProductLoading(state){
      return state.addProductLoading;
    }
  },
};

export default rentsStore;
