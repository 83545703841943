<template>
  <v-card :loading="productsLoading">
    <v-tooltip v-if="isAdmin" top class="absolute top right">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon color="error" v-bind="attrs" v-on="on" @click="handleDeleteProduct">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
    <span>Șterge</span>
  </v-tooltip>
    <template slot="progress">
      <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
    </template>
    <v-img height="250" contain :src="getImageLink(product.imageUrls[0])">
    </v-img>
    <v-card-title>
      {{ product.name }}
    </v-card-title>
    <v-card-text class="card-description">
      {{ product.description }}
    </v-card-text>
    <v-card-actions>
      <v-btn color="secondary" class="d-sm-button" @click="goToRentProductPage(product.productId)">{{ $t("buttons.rent") }}</v-btn>
      <v-btn color="primary" class="d-md-button" @click="goToProduct(product.productId)">{{ $t("buttons.viewDetails") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { loginPath, rentProductName } from "@/navigation/paths";
import { getImageLink } from "../../../store/utils/functions";
import { mapActions, mapGetters } from "vuex";
import { UserType } from "@/store/utils/userType";
export default {
  name: "ProductCard",
  props: {
    product: {},
  },
  methods: {
    ...mapActions(['deleteProduct', 'getProducts']),
    getImageLink(path) {
      if (path) {
        return getImageLink(path);
      } else return this.defaultImage;
    },
    goToRentProductPage(id) {
      if (this.currentUser) {
        this.$router.push({ name: rentProductName, params: { id } });
      } else {
        this.$router.push(loginPath);
      }
    },
    goToProduct(id){
      this.$router.push({name: "ProductDetails", params:{id}})
    },
    handleDeleteProduct(){
      this.deleteProduct({
        token:this.currentUser.jwtToken,
        id:this.product.productId
      }).then(()=>{
        this.getProducts();
      })
    }
  },
  computed: {
    ...mapGetters(["currentUser", "productsLoading"]),
    defaultImage() {
      return require('@/assets/placeholder_logo_atv.png');
    },
    isAdmin(){
      return this.currentUser?.userType == UserType.AdminUser;
    }
  },
};
</script>

<style scoped>
.v-card__text {
  text-align: left;
}
.v-card__actions {
  display: flex;
  justify-content: flex-end;
}
.rent-button {
  color: blue;
}
.card-description{
  overflow: hidden;
  word-break: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
