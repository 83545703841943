<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit, reset }">
    <h3>Rezervare</h3>
    <v-form @submit.prevent="handleSubmit(onSubmit)" ref="form"
      v-model="valid"
      lazy-validation @reset.prevent="reset">
      <v-row
        class="d-flex mt-3"
        justify-sm="center"
        justify-md="space-between"
        no-gutters
      >
        <v-col cols="12" sm="10" md="5">
          <form-data-field
            label="Dată rezervare"
            v-model="formData.rentDate"
            @input="handleRentDateChange"
            rules="required"
            :invalidDates="invalidDates"
            :minDate="
              changeDateForDataPicker(
                new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
              )
            "
          >
          </form-data-field>
        </v-col>
        <v-col cols="12" sm="10" md="5">
          <form-select-menu
            label="Tip plată"
            v-model="formData.paymentType"
            rules="required"
            :items="paymentTypeItems"
          >
          </form-select-menu>
        </v-col>
      </v-row>
      <v-row
        class="d-flex mt-3"
        justify-sm="center"
        rules="required"
        justify-md="space-between"
        no-gutters
      >
        <v-col cols="12" sm="10" md="5">
          <form-select-menu
            label="Tip rezervare"
            rules="required"
            v-model="formData.rentType"
            :items="rentTypeItems"
          >
          </form-select-menu>
        </v-col>
        <v-col cols="12" sm="10" md="5">
          <form-text-field label="Alte detalii" v-model="formData.rentDetails">
          </form-text-field>
        </v-col>
      </v-row>
      <v-row
        v-if="formData.rentType === 2"
        class="d-flex mt-3"
        justify-sm="center"
        justify-md="space-between"
        no-gutters
      >
        <v-col cols="12" sm="10" md="5">
          Oră început
          <custom-time-picker
            @hoursChange="handleStartHoursChange"
            @minutesChange="handleStartMinutesChange"
          >
          </custom-time-picker>
        </v-col>
        <v-col cols="12" sm="10" md="5"
          >Oră sfârșit<custom-time-picker
            :minHour="startHour"
            @hoursChange="handleEndHoursChange"
            @minutesChange="handleEndMinutesChange"
          >
          </custom-time-picker>
        </v-col>
      </v-row>
      <v-row no-gutters class="d-flex justify-center">
        <v-btn type="submit" small> Rezervă </v-btn>
      </v-row>
    </v-form>
  </validation-observer>
</template>



<script>
import FormDataField from "@/components/form-inputs/FormDataField.vue";
import FormTextField from "@/components/form-inputs/FormTextField.vue";
import CustomTimePicker from "@/components/shared/CustomTimePicker.vue";
import { ValidationObserver, extend } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import { parseDateForDataPicker } from "@/store/utils/functions";
import { rentTypeItems } from "@/store/utils/rentType";
import FormSelectMenu from "../form-inputs/FormSelectMenu.vue";
import { paymentTypeItems } from "@/store/utils/paymentType";
import { rentsPath } from "@/navigation/paths";

extend("required", {
  validate(value) {
    return {
      required: true,
      valid: ["", null, undefined].indexOf(value) === -1,
    };
  },
  message: "Acest câmp este obligatoriu.",
});
export default {
  name: "RentProductForm",
  components: {
    ValidationObserver,
    FormDataField,
    FormSelectMenu,
    FormTextField,
    CustomTimePicker,
  },
  data() {
    return {
      valid:false,
      formData: {
        rentDate: "",
        rentType: 0,
        rentDetails: null,
        paymentType: 0,
        startingHour: null,
        endingHour: null,
      },
      paymentTypeItems: paymentTypeItems,
      rentTypeItems: rentTypeItems,
    };
  },
  methods: {
    ...mapActions(["rentProduct"]),
    handleStartHoursChange(e) {
      var startingHourMinutes =
        this.formData.startingHour != null
          ? this.formData.startingHour.split(":")[1]
          : "0";
      this.formData.startingHour = e + ":" + startingHourMinutes;
    },
    handleStartMinutesChange(e) {
      var startingHourHours =
        this.formData.startingHour != null
          ? this.formData.startingHour.split(":")[0]
          : "0";
      this.formData.startingHour = startingHourHours + ":" + e;
    },
    handleEndHoursChange(e) {
      var endingHourMinutes =
        this.formData.endingHour != null
          ? this.formData.endingHour.split(":")[1]
          : "0";
      this.formData.endingHour = e + ":" + endingHourMinutes;
    },
    handleEndMinutesChange(e) {
      var endingHourHours =
        this.formData.endingHour != null
          ? this.formData.endingHour.split(":")[0]
          : "0";
      this.formData.endingHour = endingHourHours + ":" + e;
    },
    handleRentDateChange(e) {
      this.formData.rentDate = new Date(e);
    },
    changeDateForDataPicker(date) {
      return parseDateForDataPicker(date);
    },
    onSubmit() {
      var form = {
        ...this.formData,
        rentedDate: this.formData.rentDate.toISOString(),
        rentedByUserId: this.currentUser.userId,
        username: this.currentUser.lastName + " " + this.currentUser.firstName,
        productId: this.productDetails.productId,
      };
      this.rentProduct({
        data: {
          ...form,
        },
        token: this.currentUser.jwtToken,
      }).then(() => {
        this.$router.push(rentsPath);
        requestAnimationFrame(() => {
          this.$refs.observer.reset();
        });
      });
    },
  },
  watch: {
    rentType() {
      this.formData.startingHour = null;
      this.formData.endingHour = null;
    },
  },
  computed: {
    ...mapGetters(["productRents", "currentUser", "productDetails"]),
    invalidDates() {
      var invalidDates = this.productRents.map((rent) => {
        var date = new Date(rent.rentedDate);
        return this.changeDateForDataPicker(date);
      });
      return invalidDates;
    },
    startHour() {
      if (this.formData.startingHour)
        return this.formData.startingHour.split(":")[0];
      else return undefined;
    },
    rentType() {
      return this.formData.rentType;
    },
  },
};
</script>
