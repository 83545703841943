<template>
    <add-product></add-product>
</template>

<script>
import AddProduct from "@/components/products/add-product/AddProduct.vue";
import "./AddOrEditProductPage.css";
export default {
  name: "AddOrEditProductPage",
  components:{
    AddProduct
  }
};
</script>
