import axios from "axios";
import { baseUrl } from "@/config/apiConfig";

const paymentStore = {
  state() {
    return {
      payments: [],
      paymentsLoading: false,
      paymentData:{
        env_Key:null,
        data:null,
        url:null
      }
    };
  },
  mutations: {
    getPaymentsPending(state) {
      state.paymentsLoading = true;
      state.payments = [];
    },
    setPayments(state, data) {
      state.payments = data;
    },
    getPaymentsFulfilled(state) {
      state.paymentsLoading = false;
    },
    getPaymentsRejected(state) {
      state.payments = [];
      state.paymentsLoading = false;
    },
    setPaymentData(state, data){
      console.log(data)
      state.paymentData = data;
    }
  },
  actions: {
    async getPayments({ commit }, userId) {
      commit("getPaymentsPending");
      await axios
        .get(baseUrl + "Payments/GetPayments", {
          params: {
            userId: userId,
          },
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          commit("setPayments", response?.data?.response);
          commit("getPaymentsFulfilled");
        })
        .catch(() => {
          commit("getPaymentsRejected");
        });
    },
    async goToPayment({commit}, data) {
      await axios
        .post(baseUrl + "Payment/GoToPayment",data?.data,{
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          withCredentials: true,
        })
        .then((response) => {
          commit("setPaymentData", response?.data?.response)
        })
        .catch(() => {
        });
    },
  },
  getters: {
    payments(state) {
      return state.payments;
    },
    paymentsLoading(state) {
      return state.paymentsLoading;
    },
    paymentData(state){
      return state.paymentData;
    }
  },
};

export default paymentStore;
