<template>
      <v-dialog v-model="showDeleteDialog" max-width="400">
        <v-card>
          <v-card-text>
            Sigur vrei să ștergi acest utilizator ?
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="primary" @click="handleDeleteUser(userId)">Confirm</v-btn>
            <v-btn color="red" @click="cancelDelete">Anulează</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </template>
  
  <script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

  export default {
    props: {
      userId: String,
      show:Boolean
    },
    methods: {
        ...mapActions(['deleteUser', 'getUsers']),
        ...mapMutations(['setDeleteConfirmationDialog']),
      handleDeleteUser(userId) {
            this.deleteUser({
                token:this.currentUser?.jwtToken,
                userId:userId
            }).then(()=>{
              this.getUsers({
                token:this.currentUser?.jwtToken
              })
              this.setDeleteConfirmationDialog(false);
            })
      },
      cancelDelete() {
        this.setDeleteConfirmationDialog(false);
      },
  
    },
    computed:{
        ...mapGetters(['currentUser', 'showDeleteDialog'])
    }
  };
  </script>