<template>
  <v-container fluid >
    <v-row no-gutters class="d-flex justify-space-between align-center">
      <v-col cols="12" sm="12" md="6">
        <product-details :product="productDetails"> </product-details>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="d-flex justify-center pt-6">
        <rent-product-form></rent-product-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RentProductForm from "@/components/rent-product/RentProductForm.vue";
import { mapActions, mapGetters } from "vuex";
import ProductDetails from "@/components/rent-product/ProductDetails.vue";
export default {
  name: "RentProductPage",
  components: {
    RentProductForm,
    ProductDetails,
  },

  beforeMount() {
    this.getProductDetails(this.productId);
    this.getProductRentedTimes(this.productId);
  },
  computed: {
    ...mapGetters(["productDetails"]),
    productId() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions(["getProductDetails", "getProductRentedTimes"]),
  },
};
</script>
