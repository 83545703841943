<template>
  <GmapMap
    :center="centerMarker.position"
    :zoom="10"
    map-type-id="terrain"
    style="width: 100%; height: 500px"
    ref="mapRef"
    :options="{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUi: false,
    }"
  >
    <gmap-marker :position="centerMarker.position"> </gmap-marker>
    <gmap-circle
      :options="{ fillColor: 'red', fillOpacity: 0.1 }"
      :center="centerMarker.position"
      :radius="10000"
      :visible="true"
    ></gmap-circle>
  </GmapMap>
</template>

<script>
export default {
  name: "InfoMap",
  mounted() {},
  computed: {
    centerMarker() {
      return {
        name: "My house",
        position: { lat: 46.145, lng: 23.478 },
      };
    },
  },
};
</script>
