<template>
    <v-container>
      <v-layout align-center justify-center>
        <v-card>
          <v-card-title class="headline">Resetare parolă</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="resetPassword">
              <v-text-field
                v-model="newPassword"
                label="Parola nouă"
                type="password"
                required
              ></v-text-field>
  
              <v-text-field
                v-model="confirmPassword"
                label="Confirmă parola"
                type="password"
                required
                :rules="[() => confirmPassword === newPassword || 'Parolele nu coincid']"
              ></v-text-field>
  
              <v-btn type="submit" color="primary">Trimite</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-layout>
    </v-container>
  </template>
  
  <script>
import { resetPasswordSuccessPath } from '@/navigation/paths';
import { mapActions } from 'vuex';

  export default {
    name:"ResetPassword",
    data() {
      return {
        newPassword: '',
        confirmPassword: '',
      };
    },
    methods: {
      ...mapActions(['handleResetPassword']),
      resetPassword() {
        this.handleResetPassword({
          token:this.$route.query.token,
          password:this.newPassword,
          confirmPassword:this.confirmPassword,
        }).then((response)=>{
          if(response.success){
            this.$router.push(resetPasswordSuccessPath)
          }
        })
      },
    },
  };
  </script>