<template>
  <v-dialog v-model="open" width="600">
    <v-card>
      <v-row
        no-gutters
        class="d-flex justify-space-between align-center text-h4 pa-4"
      >
        <v-col cols="3"></v-col>
        <v-col cols="6">{{ productDetails.productName }}</v-col>
        <v-col cols="3" class="d-flex justify-end"
          ><v-btn @click="open = false" small icon color="error">
            <v-icon> mdi-close-circle </v-icon>
          </v-btn></v-col
        >
      </v-row>
      <v-card-subtitle>
        {{ dateLabel(rentDetails.rentedDate) }}
      </v-card-subtitle>
      <v-card-text v-if="rentDetails.rentType == 2" class="text-h6">
        De la
        <span class="font-weight-bold">{{ timeLabel(rentDetails.start) }}</span>
        până la
        <span class="font-weight-bold">{{ timeLabel(rentDetails.end) }}</span>
      </v-card-text>
      <v-card-text
        v-if="rentDetails.rentType == 1"
        class="text-h6 font-weight-bold"
        >Toată ziua
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <template v-if="rentDetails.status == 1 && isAdmin(currentUser)">
          <v-btn color="success" @click="handleConfirmRent(rentDetails.rentId)">
            Confirmat
          </v-btn>
          <v-btn color="error" @click="handleRejectRent(rentDetails.rentId)"> Respins </v-btn>
        </template>
        <template v-if="rentDetails.status == 1 && isSimple(currentUser)">
          <v-btn color="error" @click="handleCancelRent(rentDetails.rentId)">
            Anulează
          </v-btn>
        </template>
        <template v-if="rentDetails.status == 5 && isSimple(currentUser)">
          <v-btn color="success"> Plătește </v-btn>
          <v-btn color="error" @click="handleRejectRent(rentDetails.rentId)">
            Respins
          </v-btn>
        </template>
        <template v-if="rentDetails.status == 5 && isAdmin(currentUser)">
          <v-btn
            color="success"
            @click="handleConfirmRentPayment(rentDetails.rentId)"
            >Confirmă plata</v-btn
          >
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  getDateLabel,
  getRentTime,
  isAdmin,
  isSimple,
} from "@/store/utils/functions";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ProductRentDetails",
  data() {
    return {
      open: false,
      productDetails:{}
    };
  },
  props: {
    rentDetails: {},
  },
  methods: {
    ...mapActions([
      "confirmRent",
      "confirmRentPayment",
      "cancelRent",
      "getMyRents",
    ]),
    dateLabel: function (date) {
      return getDateLabel(date);
    },
    timeLabel: function (date) {
      return getRentTime(date);
    },
    isSimple(user) {
      return isSimple(user);
    },
    isAdmin(user) {
      return isAdmin(user);
    },
    handleConfirmRent(rentId) {
      this.confirmRent({
        rentId: rentId,
        token: this.currentUser.jwtToken,
      }).then(() => {
        this.getMyRents(this.currentUser.userId);
      });
    },
    handleRejectRent(rentId) {
      this.cancelRent({
        rentId: rentId,
        token: this.currentUser.jwtToken,
      }).then(() => {
        this.getMyRents(this.currentUser.userId);
      });
    },
    handleConfirmRentPayment(rentId) {
      this.confirmRentPayment({
        rentId: rentId,
        token: this.currentUser.jwtToken,
      }).then(() => {
        this.getMyRents(this.currentUser.userId);
      });
    },
    handleCancelRent(rentId){
      this.cancelRent({
        rentId: rentId,
        token: this.currentUser.jwtToken,
      }).then(() => {
        this.getMyRents(this.currentUser.userId);
      });
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
