import { Store } from "vuex";
import Vuex from "vuex";
import Vue from "vue";
import authenticationStore from "./modules/authentication/authenticationStore";
import usersStore from "./modules/users/usersStore";
import productStore from "./modules/products/productStore";
import rentsStore from "./modules/rents/rentStore";
import paymentStore from "./modules/payments/paymentStore";
Vue.use(Vuex);
Vue.config.devtools = true;
export const store = new Store({
  modules: {
    authentication: authenticationStore,
    users: usersStore,
    product: productStore,
    rents: rentsStore,
    payments: paymentStore,
  },
  state() {
    return {
      count: 0,
      todos: [],
      overlay: false,
      progressBarFinished: false,
      progressBarLoading: false,
    };
  },
  mutations: {
    increment(state) {
      state.count++;
    },
    addItem(state, payload) {
      state.todos.push(payload);
    },
    addItems(state, payload) {
      state.todos.push(payload);
    },
    progressBarLoadingFinished(state) {
      state.progressBarFinished = true;
      state.progressBarLoading = false;
    },
    showOverlay(state) {
      state.overlay = true;
    },
    hideOverlay(state) {
      state.overlay = false;
    },
  },
  getters: {
    overlay: (state) => {
      return state.overlay;
    },
  },
  actions: {
  },
});
