import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "@/navigation/routes";
Vue.use(VueRouter);

const router = new VueRouter({
  routes,
});

router.beforeResolve((to, from, next) => {
  next();
});

Vue.use(router);

export default router;
