<template>
  <validation-provider v-slot="{ errors }" :name="label" :rules="rules">
    <v-textarea
      v-model="innerValue"
      :error-messages="errors"
      :label="label"
      outlined
      dense
      :disabled="readonly"
      :type="type"
      v-bind="$attrs"
    ></v-textarea>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
export default {
  name: "FormTextArea",
  data() {
    return {
      innerValue: null,
    };
  },
  components: {
    ValidationProvider,
  },
  props: {
    value: null,
    label: String,
    rules: [String],
    type: String,
    readonly: Boolean,
  },
  mounted() {
    this.innerValue = this.value;
  },
  watch: {
    innerValue(newInnerValue) {
      this.$emit("input", newInnerValue);
    },
  },
};
</script>
