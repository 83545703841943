<template>
    <v-container class="d-flex fill-height align-center justify-center">
    <v-row >
      <v-col v-for="(card, index) in cards" :key="index" cols="12" md="4">
        <v-card>
          <v-carousel hide-delimiter-background height="300">
                                    <v-carousel-item v-for="(image, i) in card.images" :key="i"
                                        :src="image" contain reverse-transition="fade-transition"
                                        transition="fade-transition">
                                    </v-carousel-item>
                                </v-carousel>
          <v-card-title>{{ card.title }}</v-card-title>
          <v-card-text>{{ card.description }}</v-card-text>
        </v-card>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import iezerImage from "@/assets/hartatraseuiezergps.jpg";
import piatraImage from "@/assets/hartatraseupiatragps.png";
import poianaImage from "@/assets/hartatraseupoianagps.png";
export default {
    name: "RoutesPage",
    data() {
    return {
      cards: [
        {
          title: 'Lacul Iezer',
          images: [iezerImage],
          description: 'Lacul Iezer Ighiel impresionează prin frumusețea sa pitorească, reflectând cerul senin și munții împăduriți în apele sale cristaline, creând un peisaj idilic și relaxant.',
        },
        {
          title: 'Piatra Grohotișului',
          images: [piatraImage],
          description: 'Piatra Grohotișului se remarcă prin aspectul său impunător și variatele nuanțe de gri, oferind un peisaj dramatic și sălbatic în Munții Carpați, cu stânci abrupte și forme fascinante sculptate de trecerea timpului.',
        },
        {
          title: 'Poiana Dragostei',
          images: [poianaImage],
          description: 'Poiana Dragostei încântă privirile cu o paletă cromatică bogată, unde florile parfumate și ierburile înverzite dansează sub lumina soarelui, creând un tablou pastoral de o frumusețe sublimă și romantică în inima naturii.',
        },
      ],
    }
  }
}
</script>

<style scoped>

.fill-height {
  height: 100%;
}
</style>