import { baseUrl } from "@/config/apiConfig";
import axios from "axios";

const authenticationStore = {
  state() {
    return {
      authenticationLoading: false,
      currentUser: null,
      isRefreshing: false,
      finishedRefreshingToken: false,
      isSilentRefresh: false,
    };
  },
  mutations: {
    loginPending(state) {
      state.authenticationLoading = true;
    },
    loginFulfilled(state, data) {
      localStorage.setItem("user", JSON.stringify(data.jwtToken));
      state.currentUser = data;
      state.authenticationLoading = false;
    },
    loginRejected(state) {
      state.currentUser = null;
      if (localStorage.getItem("user")) localStorage.removeItem("user");
      state.authenticationLoading = false;
    },
    refreshTokenPending(state) {
      state.isRefreshing = true;
      state.finishedRefreshingToken = false;
    },
    refreshTokenFulfilled(state, data) {
      localStorage.setItem("user", JSON.stringify(data.jwtToken));
      state.currentUser = data;
      state.isRefreshing = false;
      state.isSilentRefresh = false;
      state.finishedRefreshingToken = true;
    },
    refreshTokenRejected(state) {
      state.currentUser = null;
      state.isRefreshing = false;
      state.finishedRefreshingToken = true;
      state.isSilentRefresh = false;
      if (localStorage.getItem("user")) localStorage.removeItem("user");
    },
    logoutPending() {},
    logoutFulfilled(state) {
      state.currentUser = null;
      if (localStorage.getItem("user")) localStorage.removeItem("user");
    },
    logoutRejected(state) {
      state.currentUser = null;
      if (localStorage.getItem("user")) localStorage.removeItem("user");
    },
    setCurrentUser(state, data) {
      state.currentUser = data;
    },
  },
  actions: {
    async login({ commit }, params) {
      try {
        commit("showOverlay");
        commit("loginPending");
        var { data } = await axios.post(
          baseUrl + "Users/Login",
          {
            email: params.email,
            password: params.password,
          },
          {
            withCredentials: true,
          }
        );
        if (data.success) {
          commit("loginFulfilled", data.response);
          commit("showNotification", data.message);
        } else {
          commit("loginRejected");
          commit("showNotification", data.message);
        }
        commit("hideOverlay");
        return data;
      } catch (error) {
        commit("hideOverlay");
        commit("showNotification", error.response.data.message);
      }
    },

    async refreshToken(context, params) {
      context.commit("refreshTokenPending");
      axios
        .post(
          baseUrl + "Users/RefreshToken",
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${params}`,
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          if (response.data.success) {
            context.commit("refreshTokenFulfilled", response.data.response);
          } else {
            context.commit("refreshTokenRejected");
          }
        })
        .catch(() => {});
    },
    async logout(context, params) {
      try {
        context.commit("showOverlay");
        context.commit("logoutPending");
        var { data } = await axios.post(
          baseUrl + "Users/RevokeToken",
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${params}`,
            },
            withCredentials: true,
          }
        );

        if (data.success) {
          context.commit("logoutFulfilled", data.response);
        } else {
          context.commit("logoutRejected");
        }
        context.commit("hideOverlay");
        return data;
      } catch (error) {
        context.commit("hideOverlay");
      }
    },
    async forgotPassword(context, data) {
      context.commit("forgotPasswordPending");
      await axios
        .post(
          baseUrl + "Users/ForgotPassword",
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
            params: data,
          }
        )
        .then(() => {
          context.commit("forgotPasswordFulfilled");
        })
        .catch(() => {
          context.commit("forgotPasswordRejected");
        });
    },
    async resetPassword(context, data) {
      context.commit("resetPasswordPending");
      await axios
        .post(baseUrl + "Users/ResetPassword", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          context.commit("resetPasswordFulfilled");
        })
        .catch(() => {
          context.commit("resetPasswordRejected");
        });
    },
    async verifyEmail(context, data) {
      context.commit("verifyEmailPending");
      context.commit("showOverlay");
      var promise = await axios
        .post(
          baseUrl + "Users/VerifyEmail",
          {
            token: data.token,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          context.commit("verifyEmailFulfilled");
          context.commit("hideOverlay");
        })
        .catch(() => {
          context.commit("verifyEmailRejected");
          context.commit("hideOverlay");
        });
      return promise;
    },
    async resendVerificationEmail(context, data) {
      context.commit("resendVerificationEmailPending");
      await axios
        .get(baseUrl + "Users/ResendVerificationEmail", {
          params: {
            email: data.email,
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          context.commit("resendVerificationEmailFulfilled");
        })
        .catch(() => {
          context.commit("resendVerificationEmailRejected");
        });
    },
  },
  getters: {
    authenticationLoading: (state) => {
      return state.authenticationLoading;
    },
    currentUser: (state) => {
      return state.currentUser;
    },
    isRefreshing: (state) => {
      return state.isRefreshing;
    },
    finishedRefreshingToken: (state) => {
      return state.finishedRefreshingToken;
    },
  },
};

export default authenticationStore;
