<template>
  <v-system-bar
    height="50px"
    color="secondary"
    id="header-component"
    class="d-flex justify-space-between"
    app
  >
    <v-breadcrumbs large :items="routes" v-if="$vuetify.breakpoint.mdAndUp">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="getToPath(item.path)">
          <v-icon :class="getRouteClassName(item.path)">{{ item.icon }}</v-icon>
          <span :class="getRouteClassName(item.path)">{{ item.title }}</span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-btn icon @click="toggleMenu" v-else>
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-menu v-model="menu" offset-y>
        <template ></template>
        <v-list>
          <v-list-item v-for="item in routes" :key="item.title" :to="getToPath(item.path)" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    <v-btn v-if="currentUser" @click="handleLogout" x-small color="red">
      Logout
    </v-btn>
  </v-system-bar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { loginPath, myProfilePath } from "../../navigation/paths";
import { loggedInRoutes, visitorRoutes } from "../../navigation/routes";
export default {
  name: "HeaderComponent",
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    ...mapActions(["logout"]),
    handleLogout() {
      this.logout(this.currentUser.jwtToken).then((response) => {
        if (response.success) {
          if (this.$route.path !== loginPath) this.$router.push(loginPath);
        }
      });
    },
    getRouteClassName(path) {
      if (
        this.currentUser &&
        this.$route.path === "/profilul-meu/" + this.currentUser.userId &&
        path.includes("profilul-meu")
      )
        return "black--text";
      if (this.$route.path === path) return "black--text";
      else return "white--text";
    },
    getToPath(path) {
      if (path === myProfilePath) {
        return "/profilul-meu/" + this.currentUser.userId;
      } else return path;
    },
    toggleMenu() {
      this.menu = !this.menu;
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    routes() {
      if (this.currentUser) {
        var routes = [];
        loggedInRoutes.forEach((route) => {
          if (route.roles.includes(this.currentUser.userType)) {
            routes.push(route);
          }
        });
        return routes;
      }
      return visitorRoutes;
    },
  },
  watch: {},
};
</script>
