import { en } from "@/localization/en";
import { ro } from "@/localization/ro";
import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: en,
  ro: ro,
};

export const i18n = new VueI18n({
  locale: "ro", // set locale
  fallbackLocale: "en",
  messages, // set locale messages
});
