export const en = {
  routesTitles: {
    home: "Home",
    login: "Login",
    candidates: "Candidates",
    users: "Users",
    logout: "Logout",
    register: "Register",
    test: "Test",
    playingTipTap: "TipTap",
  },
};
