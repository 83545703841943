<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-card class="form-container">
        <v-row>
          <v-col cols="12" class="text-title">
            <span class="pb-5"
              >Te rugăm să te autentifici pentru a rezerva unul dintre
              ATV-urile noastre. Introdu aici numele de utilizator și parola
              pentru a te conecta.</span
            >
          </v-col>
          <v-col cols="12" xs="12">
            <v-text-field
              v-model="email"
              dense
              outlined
              label="Email"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12">
            <v-text-field
              v-model="password"
              dense
              outlined
              type="password"
              label="Parola"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12">
            <v-btn outlined @click="handleLoginClick">
              {{ $t("buttons.login") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <div class="mt-5">
        Nu aveți cont? Înregistrați-vă
        <span
          @click="handleRegisterClick"
          class="forgot-password-text primary--text"
          >aici</span
        >
      </div>
      <div
        @click="handleForgotPasswordClick"
        class="mt-5 forgot-password-text primary--text"
      >
        Ați uitat parola?
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import {
  forgotPasswordPath,
  homePath,
  registerPath,
} from "../../navigation/paths";
import "./LoginPage.scss";
export default {
  name: "LoginPage",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    ...mapActions(["login"]),
    handleLoginClick() {
      this.login({
        email: this.email,
        password: this.password,
      }).then((response) => {
        if (response && response.success) {
          this.$router.push(homePath);
        }
      });
    },
    handleForgotPasswordClick() {
      this.$router.push(forgotPasswordPath);
    },
    handleRegisterClick() {
      this.$router.push(registerPath);
    },
  },
};
</script>
