<template>
    <v-row no-gutters class="d-flex flex-column justify-center">
      <v-data-table
        :headers="headers"
        :loading="rentsLoading"
        :items="rents"
        :page.sync="page"
        height="540"
        :items-per-page="10"
        class="elevation-1"
        hide-default-footer
        @page-count="pageCount = $event"
      >
        <template v-slot:[`item.rentedDate`]="{ item }"
          >{{ dateLabel(item.rentedDate) }}
        </template>
        <template v-slot:[`item.paymentConfirmed`]="{ item }"
          >{{ item.paymentConfirmed ? "DA" : "NU" }}
        </template>
        <template v-slot:[`item.rentType`]="{ item }"
          >{{ item.rentType == 1 ? "Pe zi" : "Pe oră" }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            color="secondary"
            @click="handleRentDetailsDialogOpen(item)"
            small
            >Detalii</v-btn
          >
          <template>
                  <product-rent-details
                    ref="productRentDetailsDialog"
                    :rentDetails="item"
                  >
                  </product-rent-details> </template>
        </template>
      </v-data-table>
      
      <!-- <pay-for-rent-component ref="payRef"></pay-for-rent-component> -->
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </v-row>
  </template>
  
  <script>
  import { getDateLabel } from "@/store/utils/functions";
  import {  paymentTypeItems } from "@/store/utils/paymentType";
  import ProductRentDetails from "@/components/existent-rents/ProductRentDetails.vue";
  import { mapGetters } from "vuex";
  import { RentStatus } from "@/store/utils/rentStatus";
  export default {
    name: "RentsDataTable",
    components: {
    ProductRentDetails,
  },
    data() {
      return {
        page: 1,
        pageCount: 0,
      };
    },
    methods: {
          paymentTypeLabel(type) {
      return paymentTypeItems.find((x) => x.value === type).label;
    },
    handleRentDetailsDialogOpen(item) {
      this.$refs.productRentDetailsDialog.productDetails = item;
      this.$refs.productRentDetailsDialog.open = true;
    },
      dateLabel(date) {
        return getDateLabel(date);
      },
      handleGoToPayment(item){
        this.goToPayment({
          data:{
          userId:item.userId,
          email:this.currentUser?.email,
          rentId:item.rentId
        },
        token:this.currentUser?.jwtToken}).then(()=>{
          this.$refs.payRef.goToPayment();
        })
      }
    },
    computed: {
        ...mapGetters(["rents", "rentsLoading", "productsNames", "currentUser"]),
      headers() {
        return [
          { text: "Client", align: "start", value: "name" },
          { text: "Produs rezervat", value: "productName" },
          { text: "Data rezervării", value: "rentedDate" },
          { text: "Tip", value: "rentType" },
          { text: "Acțiuni", value: "actions", align: "end", sortable: false },
        ];
      },
      RentStatus(){
        return RentStatus;
      }
    },
  };
  </script>
  