export const homePath = "/";
export const registerPath = "/register";
export const loginPath = "/login";
export const infoPath = "/info";
export const usersPath = "/users";
export const verifyEmailPath = "/verifica-email";
export const verifyEmailErrorPath = "/eroare-verificare-email";
export const rentsPath = "/inchirieri";
export const rentProductPath = "/inchiriaza/:id";
export const rentProductName = "Rent";
export const myProfilePath = "/profilul-meu/:id";
export const employeesPath = "/angajati";
export const paymentsPath = "/tranzactii";
export const forgotPasswordPath = "/am-uitat-parola";
export const addProductPath = "/adauga-atv";
export const productPath = "/detalii/:id"
export const forgotPasswordEmailSentPath = "/am-uitat-parola-mail";
export const resetPasswordPath = "/utilizatori/resetare-parola";
export const resetPasswordSuccessPath = "/utilizatori/parola-resetata-succes";
export const routesPath = "/trasee";