import { baseUrl } from "@/config/apiConfig";
import axios from "axios";

const usersStore = {
  state() {
    return {
      users: [],
      userData: null,
      notification: {
        text: "",
        show: false,
        type: "red",
      },
      showDeleteDialog:false,
    };
  },
  mutations: {
    addToUsers(state, data) {
      state.users.push(data);
    },
    removeFromUsers(state, data) {
      state.users = state.users.filter(
        (x) => JSON.stringify(x) !== JSON.stringify(data)
      );
    },
    setUserData(state, data) {
      state.userData = data;
    },
    showNotification(state, data) {
      state.notification = {
        show: true,
        text: data.text,
        type: data.messageType,
      };
    },
    catchNotification(state, data) {
      state.notification = {
        show: true,
        text: data.response.data.message.text,
        type: data.response.data.message.messageType,
      };
    },
    setUsers(state,data){
      state.users = data;
    },
    setDeleteConfirmationDialog(state, data){
      state.showDeleteDialog = data;
    }
  },
  actions: {
    async registerUser(context, dto) {
      try {
        context.commit("showOverlay");
        var { data } = await axios.post(baseUrl + "Users/RegisterUser", dto, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        });
        context.commit("hideOverlay");
        context.commit("showNotification", data.message);
        return data;
      } catch (error) {
        context.commit("hideOverlay");
        context.commit("showNotification", error.response.data.message);
      }
    },
    async updateUser({ commit }, dto) {
      try {
        commit("showOverlay");
        var { data } = await axios.post(
          baseUrl + "Users/UpdateUser",
          dto.form,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${dto.token}`,
            },
          }
        );
        commit("hideOverlay");
        commit("showNotification", data.message);
      } catch (error) {
        commit("hideOverlay");
        commit("showNotification", error.data.response.message);
      }
    },
    async getUserById({ commit }, data) {
      commit("showOverlay");
      commit("setUserData", null);
      await axios
        .get(baseUrl + "Users/GetUser", {
          params: {
            id: data.userId,
          },
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
        })
        .then((response) => {
          commit("setUserData", response.data.response);
          commit("hideOverlay");
        })
        .catch(() => {
          commit("hideOverlay");
        });
    },
    async sendContactEmail({ commit }, data) {
      commit("showOverlay");
      await axios
        .post(baseUrl + "Users/SendContactEmail", data, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          commit("hideOverlay");
          return response;
        })
        .catch(() => {
          commit("hideOverlay");
        });
    },
    async sendForgotPasswordEmail({ commit }, dto) {
      commit("showOverlay");
      var {data} = await axios
        .post(baseUrl + "Users/ForgotPassword", dto, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        });
        commit("hideOverlay");
        return data;
    },
    async getUsers({commit},data){
      await axios.get(baseUrl + "Users/GetUsers",{
        withCredentials:true,
        headers:{
          "Content-Type":"application/json",
          Authorization: `Bearer ${data.token}`,
        },
      }).then((response)=>{
        commit("setUsers", response?.data?.response);
      }).catch((error)=>{
        commit("showNotification", error.response.data.message);
      })
    },
    async deleteUser({commit}, dto){
      try {
        commit("showOverlay");
        var form = new FormData();
        form.append('id', dto.userId);
        var { data } = await axios.post(
          baseUrl + "Users/DeleteUser",
          form,
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${dto.token}`,
            },
          }
        );
        commit("hideOverlay");
        commit("showNotification", data.message);
      } catch (error) {
        commit("hideOverlay");
        commit("showNotification", error.data.response.message);
      }
    },
    async handleResetPassword({commit}, dto){
      commit("showOverlay");
      var {data} = await axios
        .post(baseUrl + "Users/ResetPassword", dto, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        })
        commit("hideOverlay");
        return data;
    }
  },
  getters: {
    userData(state) {
      return state.userData;
    },
    employees(state) {
      return state.employees;
    },
    notification(state) {
      return state.notification;
    },
    users(state){
      return state.users;
    },
    showDeleteDialog(state){
      return state.showDeleteDialog;
    }
  },
};

export default usersStore;
