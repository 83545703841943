export const ro = {
  routesTitles: {
    home: "Acasă",
    login: "Login",
    candidates: "Candidați",
    users: "Utilizatori",
    logout: "Logout",
    register: "Înregistrare",
    test: "Test",
    playingTipTap: "TipTap",
    info: "Informații",
    rents: "Rezervări",
    routes: "Trasee"
  },
  fields: {
    firstName: "Nume",
    lastName: "Prenume",
    email: "E-mail",
    age: "Vârsta",
    phoneNumber: "Număr telefon",
    password: "Parola",
    confirmPassword: "Confirmă parola",
  },
  buttons: {
    register: "Înregistrare",
    send: "Trimite",
    login: "Autentificare",
    rent: "Rezervă",
    viewDetails: "Vezi detalii",
  },

  messages: {
    exactLength: "askdaskd",
  },
};
