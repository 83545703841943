<template>
  <validation-provider v-slot="{ errors }" :name="label" :rules="rules">
    <v-menu
      ref="menu1"
      v-model="menu1"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="dateDisplayed"
          :error-messages="errors"
          :label="label"
          outlined
          dense
          append-icon="mdi-calendar"
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        locale="ro-RO"
      :error-messages="errors"
        :value="dateValue"
        @input="handleDateChange"
        :min="minDate"
        :allowed-dates="allowedDates"
        no-title
      ></v-date-picker>
    </v-menu>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { parseDateForDataPicker } from "@/store/utils/functions";
export default {
  name: "FormDataField",
  data() {
    return {
      menu1: false,
      innerValue: null,
    };
  },
  props: {
    value: null,
    label: String,
    rules: [String],
    invalidDates: [],
    minDate: String,
  },
  components: {
    ValidationProvider,
  },
  methods: {
    handleDateChange(e) {
      this.innerValue = new Date(e);
      this.menu1 = false;
    },
    digitLabel(digit) {
      if (digit <= 9) return "0" + digit;
      return digit;
    },
    allowedDates(val) {
      if (!this.invalidDates) return val;
      if (!this.invalidDates.includes(val)) return val;
    },
  },
  mounted() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  updated() {},
  watch: {
    innerValue(newInnerValue) {
      this.$emit("input", newInnerValue);
    },
  },
  computed: {
    dateDisplayed() {
      if (!this.innerValue) return "";
      var date = new Date(this.innerValue);
      if (date) {
        const [month, day, year] = [
          date.getMonth(),
          date.getDate(),
          date.getFullYear(),
        ];
        var monthie = month + 1;
        return (
          this.digitLabel(day) + "." + this.digitLabel(monthie) + "." + year
        );
      }
      return "";
    },
    dateValue() {
      if (!this.innerValue) return null;
      var date = new Date(this.innerValue);
      if (date) {
        return parseDateForDataPicker(date);
      }
      return "";
    },
    notAllowedDates() {
      return this.invalidDates;
    },
  },
};
</script>
