<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit, reset }">
    <div class="text-h4 mt-4">Contactează-ne</div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="reset"
    >
      <v-container class="d-block elevation-2 pa-10">
        <v-row no-gutters class="d-flex" justify="center">
          <v-col cols="12" xs="12" sm="4" class="pa-2">
            <form-text-field
              rules="required"
              label="Nume"
              v-model="formData.name"
            >
            </form-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="4" class="pa-2">
            <form-text-field
              rules="required"
              label="Adresa de email"
              v-model="formData.email"
            >
            </form-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="d-flex" justify="center">
          <v-col cols="12" xs="12" sm="4" class="pa-2">
            <form-text-field
              rules="required"
              label="Număr de telefon"
              v-model="formData.phoneNumber"
            >
            </form-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="4" class="pa-2">
            <form-data-field
              label="Dată rezervare"
              v-model="formData.rentDate"
              :minDate="
                changeDateForDataPicker(
                  new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
                )
              "
            >
            </form-data-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="d-flex" justify="center">
          <v-col cols="12" xs="12" sm="4" class="pa-2">
            <form-select-menu
              label="Tipul de ATV/E-bike dorit"
              v-model="formData.atvWanted"
              :items="productsItems"
            >
            </form-select-menu>
          </v-col>
          <v-col cols="12" xs="12" sm="4" class="pa-2">
            <form-select-menu
              label="Durata rezervării  dorită"
              v-model="formData.rentPeriod"
              :items="rentTimeItems"
            >
            </form-select-menu>
          </v-col>
        </v-row>
        <v-row no-gutters class="d-flex" justify="center">
          <v-col cols="12" xs="12" sm="8" class="pa-2">
            <form-text-area
              label="Mesajul dumneavoastră"
              v-model="formData.message"
            >
            </form-text-area>
          </v-col>
        </v-row>

        <v-row no-gutters class="d-flex justify-center">
          <v-btn type="submit" color="primary" small
            >Trimiteți formularul
          </v-btn>
        </v-row>
      </v-container>
    </v-form>
  </validation-observer>
</template>

<script>
import { ValidationObserver, extend } from "vee-validate";
import FormTextField from "../form-inputs/FormTextField.vue";
import FormDataField from "../form-inputs/FormDataField.vue";
import { parseDateForDataPicker } from "@/store/utils/functions";
import { mapActions } from "vuex";
import { homePath } from "@/navigation/paths";
import FormTextArea from "../form-inputs/FormTextArea.vue";
import FormSelectMenu from "../form-inputs/FormSelectMenu.vue";

extend("required", {
  validate(value) {
    return {
      required: true,
      valid: ["", null, undefined].indexOf(value) === -1,
    };
  },
  message: "Acest câmp este obligatoriu.",
});

export default {
  name: "ContactForm",
  components: {
    ValidationObserver,
    FormTextField,
    FormDataField,
    FormTextArea,
    FormSelectMenu
  },
  data() {
    return {
      valid: false,
      formData: {
        name: "",
        email: "",
        phoneNumber: "",
        rentDate: "",
        atvWanted: "",
        rentPeriod: "",
        message: "",
      },
    };
  },
  mounted(){
    this.getProducts();
  },
  methods: {
    ...mapActions(["sendContactEmail", "getProducts"]),
    onSubmit() {
      this.sendContactEmail({
        ...this.formData,
        rentDate: this.formData.rentDate
          ? new Date(this.formData.rentDate).toISOString()
          : null,
      }).then(() => {
        this.formData = {
          name: "",
          email: "",
          phoneNumber: "",
          rentDate: "",
          atvWanted: "",
          rentPeriod: "",
          message: "",
        };
        if (this.$route.path !== homePath) {
          this.$router.push(homePath);
        }
        requestAnimationFrame(() => {
          this.$refs.observer.reset();
        });
      });
    },
    changeDateForDataPicker(date) {
      return parseDateForDataPicker(date);
    },
  },
  computed: {
    products() {
      return this.$store.state.product.products;
    },
    productsLoading() {
      return this.$store.state.product.productsLoading;
    },
    productsItems(){
      return this.products.map((product)=> {
        return {
          label : product.name,
          value : product.name,
        }
      })
    },
    rentTimeItems(){
      return [
        {
          label: "1 oră",
          value: "1 oră"
        },
        {
          label: "2 ore",
          value: "2 ore"
        },
        {
          label: "3 ore",
          value: "3 ore"
        },
        {
          label: "4 ore",
          value: "4 ore"
        },
        {
          label: "o zi",
          value: "o zi"
        },
        {
          label: "o săptămână",
          value: "o săptămână"
        }
      ]
    }
  },
};
</script>
