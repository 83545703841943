import moment from "moment";
import { UserType } from "./userType";

export const getImageLink = (path) => {
  return `data:image/jpeg;base64,${path}`;
};

function digitLabel(digit) {
  if (digit <= 9) return "0" + digit;
  return digit;
}
export const parseDateForDataPicker = (date) => {
  const [month, day, year] = [
    date.getMonth(),
    date.getDate(),
    date.getFullYear(),
  ];
  var monthie = month + 1;
  return year + "-" + digitLabel(monthie) + "-" + digitLabel(day);
};

export const getDateLabel = (date) => {
  var result = moment(date).format("DD.MM.yyyy");
  if (result === "Invalid date") return "";
  return result;
};

export const getRentTime = (date) => {
  var newDate = new Date(date);
  var result = moment(newDate).format("HH:mm");
  return result;
};

export const isSimple = (user) => {
  if (user.userType === UserType.SimpleUser) return true;
  return false;
};

export const isAdmin = (user) => {
  if (user.userType === UserType.AdminUser) return true;
  return false;
};
