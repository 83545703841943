<template>
  <h1></h1>
</template>

<script>
import { homePath } from "@/navigation/paths";
export default {
  name: "VerifyEmailPage",
  methods: {
    verifyEmail() {
      this.$store
        .dispatch("verifyEmail", { token: this.$route.query.token })
        .then(() => {
          this.$router.push(homePath);
        });
    },
  },
  mounted() {
    this.verifyEmail();
  },
  computed: {},
};
</script>
