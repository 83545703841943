import Vue from "vue";
import App from "./App.vue";
import * as VueGoogleMaps from "vue2-google-maps";
import Vuex from "vuex";
import router from "./plugins/router";
import vuetify from "./plugins/vuetify";
import { store } from "./store/store";
import { i18n } from "./plugins/vuei18n";
import { googleMapsApiKey } from "./config/apiConfig";

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(VueGoogleMaps, {
  load: {
    key: googleMapsApiKey,
    libraries: "places",
  },
});
new Vue({
  render: (h) => h(App),

  router,
  vuetify,
  store,
  i18n,
}).$mount("#app");
