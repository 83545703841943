<template>
  <v-container class="d-block">
    <v-row no-gutters class="d-flex justify-center">
      <v-col v-for="product in products" :key="product.productId" class="pa-2" cols="12" sm="4">
        <product-card :product="product" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col xs="12" class="d-flex justify-end flex-column text-subtitle1">
        <span>
          Ești în căutarea unei aventuri în aer liber emoționante și palpitante?
          Nu căuta mai departe decât să rezervi unul dintre vehiculele
          noastre de teren accidentat (ATV) pentru următoarea ta excursie.
          ATV-urile noastre sunt de cea mai bună calitate, bine întreținute și
          perfecte pentru explorarea terenurilor accidentate și pentru a scăpa
          de traseele obișnuite. Imaginați-vă că traversați prin păduri dens
          populate, urcați dealuri abrupte și treceți prin pâraie stâncoase,
          toate în timp ce luați parte la frumusețea mediului natural. Plus, cu
          tarifele noastre de rezervare competitive și opțiunile
          flexibile, aveți o modalitate accesibilă și convenabilă de a
          experimenta natura. Nu pierdeți această oportunitate de a face
          amintiri neuitate cu prietenii și familia. Rezervați
          ATV-ul astăzi!
        </span>
      </v-col>
    </v-row>
    <contact-form> </contact-form>
    <v-col class="d-flex justify-center">
      <img class="imagine" :src="imagineProiect"/>
    </v-col>
  </v-container>
</template>

<script>
import ProductCard from "@/components/products/product-card/ProductCard.vue";
import { mapActions } from "vuex";
import ContactForm from "@/components/shared/ContactForm.vue";
import afirImage from "@/assets/poza_proiect.png";
export default {
  components: { ProductCard, ContactForm },
  name: "HomePage",
  methods: {
    ...mapActions(["getProducts"]),
  },
  mounted() {
    this.getProducts();
  },
  data(){
    return{
      imagineProiect: afirImage
    }
  },
  computed: {
    products() {
      return this.$store.state.product.products;
    },
    productsLoading() {
      return this.$store.state.product.productsLoading;
    },
  },
};
</script>
