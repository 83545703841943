var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"500px"},attrs:{"center":_vm.centerMarker.position,"zoom":10,"map-type-id":"terrain","options":{
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: true,
    disableDefaultUi: false,
  }}},[_c('gmap-marker',{attrs:{"position":_vm.centerMarker.position}}),_c('gmap-circle',{attrs:{"options":{ fillColor: 'red', fillOpacity: 0.1 },"center":_vm.centerMarker.position,"radius":10000,"visible":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }