<template>
  <v-app id="app">
    <header-component> </header-component>
    <!-- <v-navigation-drawer permanent app color="primary" 
      ><navigation-bar></navigation-bar
    ></v-navigation-drawer> -->

    <v-main>
      <refresh-token-component />
      <v-snackbar
        v-model="notification.show"
        :color="notification.type"
        :timeout="2000"
      >
        {{ notification.text }}
      </v-snackbar>
      <v-container v-if="!isRefreshing" fluid class="container">
        <router-view> </router-view>
        <overlay-component></overlay-component>
      </v-container>
    </v-main>

    <v-footer  app color="secondary">
      <footer-component />
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import RefreshTokenComponent from "./components/authentication/refresh-token/RefreshTokenComponent.vue";
import FooterComponent from "./components/footer/FooterComponent.vue";
import HeaderComponent from "./components/header/HeaderComponent.vue";
import OverlayComponent from "@/components/shared/Overlay.vue";
import { routes } from "@/navigation/routes";
export default {
  name: "App",
  components: {
    FooterComponent,

    RefreshTokenComponent,
    HeaderComponent,
    OverlayComponent,
  },

  computed: {
    ...mapGetters(["isRefreshing", "finishedRefreshingToken", "notification"]),
    routes() {
      return routes;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
#app {
  min-height: 100%;
  margin: 0 !important;
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black !important;
}
.container {
  justify-content: center;
  min-height: 100%;
}
</style>
