<template>
    <v-container>
      <v-layout align-center justify-center>
        <v-card>
          <v-card-title class="headline">Resetare parolă cu succes.</v-card-title>
          <v-card-text>
            <p>Parola ta a fost resetată cu succes. Te poți loga acum în contul tău.</p>
          </v-card-text>
        </v-card>
      </v-layout>
    </v-container>
  </template>
  
  <script>
  export default {
    name:"ResetPasswordSuccessPage"
    // You can add any necessary logic here
  };
  </script>