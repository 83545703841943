<template>
  <v-row no-gutters>
    <payments-data-table> </payments-data-table>
  </v-row>
</template>

<script>
import PaymentsDataTable from "@/components/payments/PaymentsDataTable.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "PaymentsPage",
  components: {
    PaymentsDataTable,
  },
  methods: {
    ...mapActions(["getPayments"]),
  },
  mounted() {
    this.getPayments(this.currentUser.userId);
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
