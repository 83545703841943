import { baseUrl } from "@/config/apiConfig";
import axios from "axios";

const productStore = {
  state() {
    return {
      products: [],
      productsLoading: false,
      productDetails: {},
    };
  },
  mutations: {
    getProductsPending(state) {
      state.products = [];
      state.productsLoading = true;
    },
    getProductsFulfilled(state, data) {
      state.products = data;
      state.productsLoading = false;
    },
    getProductDetailsPending(state) {
      state.productDetails = {};
    },
    getProductDetailsFulfilled(state, data) {
      state.productDetails = data;
    },
    getProductDetailsRejected(state) {
      state.productDetails = {};
    },
  },
  actions: {
    async getProducts(context) {
      context.commit("getProductsPending");
      await axios
        .get(baseUrl + "Products/GetProducts", {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          context.commit("getProductsFulfilled", response.data.response);
        });
    },
    async getProductDetails({ commit }, params) {
      commit("getProductDetailsPending");
      await axios
        .get(baseUrl + "Products/GetProductDetails", {
          params: {
            productId: params,
          },
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          commit("getProductDetailsFulfilled", response.data.response);
        })
        .catch(() => {
          commit("getProductDetailsRejected");
        });
    },
    async deleteProduct({commit}, dto){
      try{
      var form = new FormData();
        form.append('productId', dto.id); 
        var { data } = await axios.post(
          baseUrl + "Products/DeleteProduct",
          form,
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${dto.token}`,
            },
          }
        )
        commit("showNotification", data.message);
      }
      catch(error){
        commit("showNotification", error.data.response.message);
      }
    }
  },

  getters: {
    productsNames(state) {
      return state.products.map((product) => {
        return product.name;
      });
    },
    productDetails(state) {
      return state.productDetails;
    },
    productsLoading(state){
      return state.productsLoading;
    }
  },
};

export default productStore;
