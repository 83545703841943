<template>
  <div id="register-page">
    <h1>Înregistrare</h1>
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <v-container class="form-container">
          <v-row>
            <v-col cols="12" sm="6">
              <ValidationProvider
                name="First name"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="firstName"
                  outlined
                  :error-messages="errors"
                  dense
                  :label="$t('fields.firstName')"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6">
              <ValidationProvider
                name="Prenume"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="lastName"
                  outlined
                  dense
                  :error-messages="errors"
                  :label="$t('fields.lastName')"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6">
              <ValidationProvider
                name="Email"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="email"
                  dense
                  outlined
                  :error-messages="errors"
                  :label="$t('fields.email')"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6">
              <ValidationProvider
                name="PhoneNumber"
                rules="required|exactLength:10"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="phoneNumber"
                  dense
                  outlined
                  :error-messages="errors"
                  :label="$t('fields.phoneNumber')"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6">
              <ValidationProvider
                name="Age"
                rules="required|major"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="age"
                  dense
                  type="number"
                  outlined
                  :error-messages="errors"
                  :label="$t('fields.age')"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6">
              <ValidationProvider
                name="Password"
                rules="required|password:@Confirm password"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="password"
                  dense
                  outlined
                  :error-messages="errors"
                  type="password"
                  :label="$t('fields.password')"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6">
              <ValidationProvider
                name="Confirm password"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="confirmPassword"
                  dense
                  outlined
                  type="password"
                  :error-messages="errors"
                  :label="$t('fields.confirmPassword')"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" xs="12">
              <v-btn
                color="secondary"
                :disabled="invalid"
                depressed
                type="submit"
                >{{ $t("buttons.register") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { mapActions } from "vuex";
import { loginPath } from "@/navigation/paths";

extend("exactLength", {
  validate(value, { length }) {
    return value.length == length;
  },
  params: ["length"],
  message: "Numărul de telefon trebuie sa conțină 10 cifre.",
});

extend("required", {
  validate(value) {
    return {
      required: true,
      valid: ["", null, undefined].indexOf(value) === -1,
    };
  },
  computesRequired: true,
});
extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Parolele nu coincid",
});

extend("major", {
  validate(value) {
    return value >= 18;
  },
  message: "Trebuie să ai peste 18 ani.",
});
export default {
  name: "RegisterPage",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      valid: true,
      firstName: "",
      lastName: "",
      email: "",
      age: "",
      password: "",
      confirmPassword: "",
      phoneNumber: "",
    };
  },
  methods: {
    ...mapActions(["registerUser"]),
    onSubmit() {
      this.registerUser({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
        confirmPassword: this.confirmPassword,
        phoneNumber: this.phoneNumber,
        age: this.age,
      }).then((response) => {
        if (response.success) {
          this.$router.push(loginPath);
        }
      });
    },
  },
  watch: {},
};
</script>

<style scoped>
#register-page {
  min-height: inherit;
}
.form-container {
  min-height: inherit;
  max-width: 800px;
}
</style>
