<template>
  <v-card elevation="24" max-width="444" class="mx-auto">
    <v-system-bar lights-out></v-system-bar>
    <v-carousel hide-delimiter-background height="300">
      <template v-if="product.imageUrls && product.imageUrls.length>0">
      <v-carousel-item 
        v-for="(image, i) in product.imageUrls"
        :key="i"
        :src="getImageLink(image)"
        contain
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
    </template>
    <template v-else>
      <v-carousel-item 
       :src="this.defaultImage"
        contain
        reverse-transition="fade-transition"
        transition="fade-transition">

      </v-carousel-item>
    </template>
    </v-carousel>
    <v-list two-line>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ product.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ product.description }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action> </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { getImageLink } from "@/store/utils/functions";
export default {
  name: "ProductDetails",
  props: {
    product: {},
  },
  methods: {
    getImageLink(path) {
      return getImageLink(path)
    },
  },
  computed:{
    defaultImage() {
      return require('@/assets/placeholder_logo_atv.png');
    }
      
  }
};
</script>
