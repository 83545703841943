import HomePage from "../pages/home/HomePage.vue";
import LoginPage from "../pages/login/LoginPage.vue";
import RegisterPage from "../pages/register/RegisterPage.vue";
import UsersPage from "../pages/users/UsersPage.vue";
import InfoPage from "../pages/info/InfoPage.vue";
import VerifyEmailPage from "../pages/verify-email/VerifyEmailPage.vue";
import ErrorVerifyPage from "../pages/error-verify-page/ErrorVerifyPage.vue";
import ExistentRentsPage from "../pages/existent-rents/ExistentRentsPage.vue";
import RentProductPage from "../pages/rent-product/RentProductPage.vue";
import MyProfilePage from "../pages/my-profile/MyProfilePage.vue";
import PaymentsPage from "../pages/payments/PaymentsPage.vue";
import ForgotPasswordPage from "../pages/forgot-password/ForgotPasswordPage.vue";
import AddProductPage from "../pages/add-or-edit-product-page/AddOrEditProductPage.vue";
import ResetPasswordPage from "@/pages/forgot-password/reset-password/ResetPasswordPage.vue";
import ProductDetails from "../pages/product-details/ProductDetailsPage.vue";
import ForgotPasswordEmailSentPage from "../pages/forgot-password/ForgotPasswordEmailSentPage.vue";
import ResetPasswordSuccessPage from "@/pages/forgot-password/reset-password/ResetPasswordSuccessPage.vue";
import RoutesPage from "@/pages/routes/RoutesPage.vue";

import {
  addProductPath,
  forgotPasswordPath,
  homePath,
  infoPath,
  loginPath,
  myProfilePath,
  paymentsPath,
  productPath,
  registerPath,
  rentProductName,
  rentProductPath,
  rentsPath,
  usersPath,
  verifyEmailErrorPath,
  verifyEmailPath,
  forgotPasswordEmailSentPath,
  resetPasswordPath,
  resetPasswordSuccessPath,
  routesPath
} from "./paths";
import { UserType } from "@/store/utils/userType";

export const routes = [
  { path: homePath, component: HomePage, name: "Home" },
  { path: registerPath, component: RegisterPage, name: "Register" },
  {
    path: rentsPath,
    component: ExistentRentsPage,
    meta: {
      requireAuth: true,
    },
    name: "Rents",
  },
  { path: loginPath, component: LoginPage, name: "Login" },
  { path: usersPath, component: UsersPage, name: "Users" },
  { path: infoPath, component: InfoPage, name: "Info" },
  { path: verifyEmailPath, component: VerifyEmailPage },
  { path: verifyEmailErrorPath, component: ErrorVerifyPage },
  { path: rentProductPath, component: RentProductPage, name: rentProductName },
  { path: myProfilePath, component: MyProfilePage, name: "My profile" },
  { path: paymentsPath, component: PaymentsPage, name: "Payments" },
  {
    path: forgotPasswordPath,
    component: ForgotPasswordPage,
    name: "Forgot password",
  },
  {
    path: forgotPasswordEmailSentPath,
    component: ForgotPasswordEmailSentPage,
    name: "Forgot password email sent",
  },
  {
    path: resetPasswordPath,
    component: ResetPasswordPage,
    name: "Reset password",
  },
  {
    path: resetPasswordSuccessPath,
    component: ResetPasswordSuccessPage,
    name: "Reset password success",
  },
  {path:addProductPath, component:AddProductPage, name:"AddProduct"},
 { path : productPath, component: ProductDetails, name :"ProductDetails"},
 {path: routesPath, component:RoutesPage, name :"Routes"}
];

export const loggedInRoutes = [
  {
    path: homePath,
    title: "Acasă",
    name: "Home",
    icon: "mdi-home",
    roles: [UserType.AdminUser, UserType.SimpleUser],
  },
  {path: routesPath, title: "Trasee", icon: "mdi-road-variant", name :"Routes", roles: [UserType.AdminUser, UserType.SimpleUser],},
  {
    path: rentsPath,
    title: "Rezervări",
    name: "Rents",
    icon: "mdi-offer",
    roles: [UserType.AdminUser, UserType.SimpleUser],
  },
  {
    path: usersPath,
    title: "Utilizatori",
    icon: "mdi-domain",
    name: "Users",
    roles: [UserType.AdminUser],
  },
  {
    path: paymentsPath,
    title: "Plăți",
    icon: "mdi-credit-card-outline",
    name: "Payments",
    roles: [UserType.AdminUser, UserType.SimpleUser],
  },
  {
    path: myProfilePath,
    title: "Profilul meu",
    icon: "mdi-account-box",
    name: "My profile",
    roles: [UserType.AdminUser, UserType.SimpleUser],
  },
  {
    path: addProductPath,
    title:"Adaugă produs",
    name:"AddProduct",
    icon:"mdi-plus",
    roles:[UserType.AdminUser]
  },
  {
    path: infoPath,
    title: "Info",
    name: "Info",
    icon: "mdi-information-outline",
    roles: [UserType.AdminUser, UserType.SimpleUser],
  },

];
export const visitorRoutes = [
  { path: homePath, title: "Acasă", icon: "mdi-home", name: "Home" },
  {path: routesPath, title: "Trasee", icon: "mdi-road-variant", name :"Routes"},
  { path: loginPath, title: "Autentificare", icon: "mdi-login", name: "Login" },
  {
    path: registerPath,
    title: "Înregistrare",
    icon: "mdi-account-plus",
    name: "Register",
  },

  {
    path: infoPath,
    title: "Info",
    name: "Info",
    icon: "mdi-information-outline",
  },
];
